/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GameOfWeekConflictResponse } from './GameOfWeekConflictResponse';
export type GameOfWeekAlreadyExistsResponse = {
  message: Record<string, any>;
  code: GameOfWeekAlreadyExistsResponse.code;
  data: Array<GameOfWeekConflictResponse>;
};
export namespace GameOfWeekAlreadyExistsResponse {
  export enum code {
    ERROR_GAME_OF_WEEK_ALREADY_EXIST = 'error.game-of-week.already-exist',
  }
}

