/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PromoDepositEntity } from './PromoDepositEntity';
import type { PromoEntity } from './PromoEntity';
export type PromoNominationEntity = {
  createdAt: string;
  updatedAt: string;
  id: number;
  promoId: number;
  userId: number;
  providerPromoId: string;
  provider: string | null;
  status: PromoNominationEntity.status;
  isFinished: boolean;
  meta: string;
  promo?: PromoEntity;
  deposit?: PromoDepositEntity;
};
export namespace PromoNominationEntity {
  export enum status {
    COLLECTING_PERMITS = 'collectingPermits',
    READY_TO_PLAY = 'readyToPlay',
    PLAYING = 'playing',
    FINISHED = 'finished',
    EXPIRED = 'expired',
    LIMIT_EXCEEDED = 'limitExceeded',
    ZERO_WIN = 'zeroWin',
  }
}

