/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DEVICE_TYPE {
  WEB_DESKTOP = 'web-desktop',
  WEB_MOBILE = 'web-mobile',
  APP_ANDROID = 'app-android',
  APP_IOS = 'app-ios',
  PWA_DESKTOP = 'pwa-desktop',
  PWA_IOS = 'pwa-ios',
  PWA_ANDROID = 'pwa-android',
  NATIVE_ANDROID = 'native-android',
  NATIVE_IOS = 'native-ios',
  CRYPTO_BOT_TG = 'crypto-bot-tg',
}
