export const getStorageData = <T>(key: string): T | undefined => {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : undefined;
  } catch (_) {
    return undefined;
  }
};

export const setStorageData = <T>(key: string, value?: T): boolean => {
  try {
    if (value !== undefined && value !== null) {
      window.localStorage.setItem(key, JSON.stringify(value));
    } else {
      window.localStorage.removeItem(key);
    }
    return true;
  } catch (_) {
    return false;
  }
};

export const removeDataFromStorage = (key: string) => setStorageData(key);
