/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum RIGHT {
  MANAGE_USERS = 'manage_users',
  MANAGE_PASSWORDS = 'manage_passwords',
  MANAGE_CHANGE_LOGS = 'manage_change_logs',
  MANAGE_SESSIONS = 'manage_sessions',
  CREATE_GAMES = 'create_games',
  READ_GAMES = 'read_games',
  UPDATE_GAMES = 'update_games',
  UPDATE_GAME_IMAGES = 'update_game_images',
  READ_GAMES_VAIX = 'read_games_vaix',
  READ_GAME_LAUNCH_URL = 'read_game_launch_url',
  READ_GAME_SESSION_DATA = 'read_game_session_data',
  MANAGE_GAME_CATEGORIES = 'manage_game_categories',
  MANAGE_GAME_TO_CATEGORIES = 'manage_game_to_categories',
  MANAGE_CATEGORY_LOCALE_SETTINGS = 'manage_category_locale_settings',
  UPLOAD_IMAGE_CATEGORY = 'upload_image_category',
  MANAGE_PROVIDER_SETTINGS = 'manage_provider_settings',
  READ_PROMO = 'read_promo',
  CREATE_PROMO = 'create_promo',
  UPDATE_PROMO = 'update_promo',
  DELETE_PROMO = 'delete_promo',
  CREATE_PROMO_NOMINATION = 'create_promo_nomination',
  READ_PROMO_NOMINATIONS = 'read_promo_nominations',
  READ_PROMO_DEPOSITS = 'read_promo_deposits',
  READ_PROMO_LOGS = 'read_promo_logs',
  READ_PROMO_STATISTICS = 'read_promo_statistics',
  MANAGE_FEED_READER_CONFIGS = 'manage_feed_reader_configs',
  READ_FEED_READER_LOGS = 'read_feed_reader_logs',
  READ_CHANGELOGS = 'read_changelogs',
  MANAGE_API_KEYS = 'manage_api_keys',
  MAKE_PROVIDER_API_CALL = 'make_provider_api_call',
  MANAGE_GAME_SETTINGS = 'manage_game_settings',
  MANAGE_CATEGORY_SETTINGS = 'manage_category_settings',
  READ_GAME_OF_WEEK = 'read_game_of_week',
  CREATE_GAME_OF_WEEK = 'create_game_of_week',
  UPDATE_GAME_OF_WEEK = 'update_game_of_week',
  DELETE_GAME_OF_WEEK = 'delete_game_of_week',
  MANAGE_RANDOM_GAME_CATEGORY = 'manage_random_game_category',
}
