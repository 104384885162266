import { CreateQueryParams, RequestQueryBuilder } from '@rewiko/crud-request';

export { CondOperator } from '@rewiko/crud-request';
export type {
  CreateQueryParams, QuerySortArr, QueryFilter, QuerySortOperator,
} from '@rewiko/crud-request';

interface IProcessedRequestObject {
  fields?: Array<string>,
  s?: string,
  filter?: Array<string>,
  or?: Array<string>,
  sort?: Array<string>,
  join?: Array<string>,
  limit?: number,
  page?: number,
}

export const createQueryObject = (
  o: CreateQueryParams,
): IProcessedRequestObject => RequestQueryBuilder.create(o).queryObject;
