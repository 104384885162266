/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocalAuthDto } from '../models/LocalAuthDto';
import type { UserResponse } from '../models/UserResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AuthApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Login by email&password
   * @returns UserResponse
   * @throws ApiError
   */
  public loginLocal({
    requestBody,
  }: {
    requestBody: LocalAuthDto,
  }): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/login/local',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid password`,
        401: `User doesn't exist`,
      },
    });
  }
  /**
   * Check auth status
   * @returns any Ok
   * @throws ApiError
   */
  public checkAuthStatus(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/auth/check',
      errors: {
        401: `Unauthorized`,
      },
    });
  }
  /**
   * Logout
   * @returns any Is session deleted
   * @throws ApiError
   */
  public logout(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/logout',
    });
  }
}
