import { ElMessage } from 'element-plus';
import { NavigationGuard, Router } from 'vue-router';
import type * as piniaStore from '@/store';
import { apiCheckAuth, ApiError } from '@/api';
import { ROUTE } from '@/router/routes-enum';
import { APP_NAME } from '@/constants/base';

const whiteList = ['/404', '/500']; // no redirect whitelist
const errorPages = ['/404', '/500'];

// Сорри, решил не париться
let firstLoad = true;

type GlobalRouterGuardType = (store: typeof piniaStore, router: Router) => NavigationGuard;
export const globalRouterGuard: GlobalRouterGuardType = (store, router) => async (to) => {
  // set page title
  document.title = to.meta.title ? `${APP_NAME} - ${to.meta.title}` : `${APP_NAME} admin`;

  const { useRouterStore, useUserStore } = store;

  try {
    // ну тут жопа пацаны
    // если роут в вайт листе, просто пускаем туда без заморочек
    if (whiteList.includes(to.path)) {
      // однако если это страница-ошибка и юзер зашел туда сам, редиректим на главную
      if (errorPages.includes(to.path) && firstLoad) {
        firstLoad = false;
        return { path: '/' };
      }
      return;
    }

    const { userInfoFetched } = useUserStore();

    // если данные юзера есть в сторе, скорее всего он авторизован
    // кикаем его со страницы авторизации
    if (userInfoFetched && to.name === ROUTE.LOGIN) {
      return { path: '/' };
    }

    if (userInfoFetched) {
      return;
    }

    // теперь интересней, если данных юзера в сторе нет, скорее всего он первый раз заходит
    // чекаем стейт авторизации
    const { authorized, fatalErr } = await apiCheckAuth();

    // если не получилось, значит прилег бэк, отправляем плакать три дня
    if (fatalErr) {
      return { name: ROUTE.ERR_500 };
    }

    // если не авторизован и идет на страницу логина, пускаем
    if (!authorized && to.name === ROUTE.LOGIN) {
      return;
    }

    // если не авторизован и лезет куда не надо, кидаем на страницу логина
    if (!authorized) {
      return {
        name: ROUTE.LOGIN,
        params: {
          redirect: to.path,
        },
      };
    }

    // если авторизован, наполняем стор данными юзера
    if (authorized) {
      try {
        const { rights } = await useUserStore().fetchUserInfo();

        // генерируем роуты, на которые он может ходить
        useRouterStore().generateRoutes(rights, router);
        return { ...to, replace: true };
      } catch (error) {
        // если не удалось получить данные юзера,
        // значит что-то не так с бэком
        return { name: ROUTE.ERR_500 };
      }
    }

    // если он авторизованный угодил на страницу авторизаци, так же кикаем его оттуда
    if (authorized && to.name === ROUTE.LOGIN) {
      return { path: '/' };
    }

    // ну ладно иди дальше
  } catch (e) {
    // при получении 401 в любом случае кидаем его на страницу логина
    if (e instanceof ApiError && e.status === 401) {
      useUserStore().resetUser();

      if (useUserStore().userInfoFetched) {
        ElMessage.error('Need to authorize');
      }

      return {
        name: ROUTE.LOGIN,
        params: {
          redirect: to.path,
        },
      };
    }
    throw e;
  }
};
