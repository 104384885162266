import { useUserStore } from '@/store';
import type { RIGHT } from '@/api';

export const useUserRights = () => {
  const userStore = useUserStore();

  const hasRights = (...requiredRights: RIGHT[]) => requiredRights.every((r) => userStore.rights.includes(r));

  return { hasRights } as const;
};
