import { createRouter as createVueRouter, createWebHistory } from 'vue-router';
import type * as piniaStore from '@/store';
import { useConfig } from '@/config/config';
import { constantRoutes } from './routes';
import { globalRouterGuard } from './routerGuard';

export const createRouter = (store: typeof piniaStore) => {
  const { baseUrl } = useConfig();

  const router = createVueRouter({
    history: createWebHistory(baseUrl),
    routes: constantRoutes,
  });

  router.beforeEach(globalRouterGuard(store, router));

  return router;
};

export const resetRouter = () => {
  if (typeof window !== 'undefined') {
    window.location.reload();
  }
};
