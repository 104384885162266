/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PromoLimits } from './PromoLimits';
export type PromoStatistics = {
  id: number;
  title: string;
  isActive: boolean;
  status: PromoStatistics.status;
  startsAt: string;
  endsAt: string;
  limits: PromoLimits | null;
};
export namespace PromoStatistics {
  export enum status {
    TEMPORARY = 'temporary',
    ACTIVE = 'active',
    EXPIRED = 'expired',
    LIMIT_EXCEEDED = 'limitExceeded',
    MANUALLY_STOPPED = 'manuallyStopped',
    DONE = 'done',
  }
}

