import { ApiError } from '@/api/generated-client';
import { client } from './client';

export const apiCheckAuth = async () => {
  try {
    await client.auth.checkAuthStatus();
    return { authorized: true, fatalErr: false };
  } catch (e) {
    if (e instanceof ApiError && e.status === 401) {
      return { authorized: false, fatalErr: false };
    }
    return { authorized: false, fatalErr: true };
  }
};
