/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PromoEntity } from './PromoEntity';
import type { PromoNominationEntity } from './PromoNominationEntity';
export type PromoDepositEntity = {
  createdAt: string;
  updatedAt: string;
  nominationId: number;
  promoId: number | null;
  userId: number;
  depositId: number | null;
  gameId: string | null;
  cursorWithdrawalId: number | null;
  provider: string;
  deviceType: string | null;
  originalAmount: number;
  amount: number;
  wagerAmount: number;
  cursorAmount: number;
  currency: string;
  status: PromoDepositEntity.status;
  wageringPercent: number;
  isFinished: boolean;
  endsAt: string;
  promo?: PromoEntity;
  nomination?: PromoNominationEntity;
};
export namespace PromoDepositEntity {
  export enum status {
    WAITING_HANDLING = 'waitingHandling',
    IN_PROGRESS = 'inProgress',
    READY_TO_PAID_OUT = 'readyToPaidOut',
    PAID_OUT = 'paidOut',
    EXPIRED = 'expired',
    CANCELED_BY_USER = 'canceledByUser',
    USER_BLOCKED = 'userBlocked',
    ZERO_WIN = 'zeroWin',
    LIMIT_EXCEEDED = 'limitExceeded',
  }
}

