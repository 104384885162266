/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GameOfWeekLocaleEmptyResponse = {
  message: Record<string, any>;
  code: GameOfWeekLocaleEmptyResponse.code;
  data: number;
};
export namespace GameOfWeekLocaleEmptyResponse {
  export enum code {
    ERROR_GAME_OF_WEEK_LOCALE_EMPTY = 'error.game-of-week.locale-empty',
  }
}

