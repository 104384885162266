/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LogResponse } from '../models/LogResponse';
import type { LOGS_ORDER } from '../models/LOGS_ORDER';
import type { ProvidersWithFeedReaderResponse } from '../models/ProvidersWithFeedReaderResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FeedReaderApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get providers which have feed reader service
   * @returns ProvidersWithFeedReaderResponse
   * @throws ApiError
   */
  public getProvidersWithFeedReader(): CancelablePromise<ProvidersWithFeedReaderResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/feed-reader/providers/list',
    });
  }
  /**
   * Get logs from feed-reader for provider
   * @returns LogResponse
   * @throws ApiError
   */
  public getFeedReaderLogs({
    providerKey,
    limit,
    start,
    from,
    until,
    order,
    fields,
  }: {
    providerKey: string,
    /**
     * logs rows limit
     */
    limit?: number,
    /**
     * logs rows offset
     */
    start?: number,
    /**
     * logs from date
     */
    from?: string,
    /**
     * logs end date
     */
    until?: string,
    /**
     * logs order count
     */
    order?: LOGS_ORDER,
    /**
     * logs fields
     */
    fields?: Array<'message' | 'timestamp' | 'level' | 'stack'>,
  }): CancelablePromise<Array<LogResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/feed-reader/logs/{providerKey}',
      path: {
        'providerKey': providerKey,
      },
      query: {
        'limit': limit,
        'start': start,
        'from': from,
        'until': until,
        'order': order,
        'fields': fields,
      },
    });
  }
  /**
   * Get api response from feed-reader for provider
   * @returns any
   * @throws ApiError
   */
  public getProviderApiCall({
    providerKey,
  }: {
    providerKey: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/feed-reader/provider-api-call/{providerKey}',
      path: {
        'providerKey': providerKey,
      },
    });
  }
  /**
   * Get a list of providers that support the getProviderApiCall method
   * @returns any
   * @throws ApiError
   */
  public getProviderApiCallList(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/feed-reader/provider-api-call-list',
    });
  }
}
