/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreateOrUpdatePromoResponse = {
  createdAt: string;
  updatedAt: string;
  id: number;
  title: string;
  events: string;
  eventsConditions: string;
  params: string;
  status: CreateOrUpdatePromoResponse.status;
  isActive: boolean;
  isTest: boolean;
  isManual: boolean;
  showInCashier: boolean;
  startsAt: string;
  endsAt: string;
};
export namespace CreateOrUpdatePromoResponse {
  export enum status {
    TEMPORARY = 'temporary',
    ACTIVE = 'active',
    EXPIRED = 'expired',
    LIMIT_EXCEEDED = 'limitExceeded',
    MANUALLY_STOPPED = 'manuallyStopped',
    DONE = 'done',
  }
}

