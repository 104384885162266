/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { ApiKeysApi } from './services/ApiKeysApi';
import { AuthApi } from './services/AuthApi';
import { CategoriesSettingsApi } from './services/CategoriesSettingsApi';
import { CategoryPresetsApi } from './services/CategoryPresetsApi';
import { ChangelogsApi } from './services/ChangelogsApi';
import { FeedReaderApi } from './services/FeedReaderApi';
import { GameCategorySettingsApi } from './services/GameCategorySettingsApi';
import { GamesApi } from './services/GamesApi';
import { GamesCategoriesApi } from './services/GamesCategoriesApi';
import { GamesSettingsApi } from './services/GamesSettingsApi';
import { HealthcheckApi } from './services/HealthcheckApi';
import { LanguagesApi } from './services/LanguagesApi';
import { PromoApi } from './services/PromoApi';
import { PromoDepositsApi } from './services/PromoDepositsApi';
import { PromoLogsApi } from './services/PromoLogsApi';
import { PromoNominationsApi } from './services/PromoNominationsApi';
import { ProviderConfigsApi } from './services/ProviderConfigsApi';
import { UsersApi } from './services/UsersApi';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class ApiClient {
  public readonly apiKeys: ApiKeysApi;
  public readonly auth: AuthApi;
  public readonly categoriesSettings: CategoriesSettingsApi;
  public readonly categoryPresets: CategoryPresetsApi;
  public readonly changelogs: ChangelogsApi;
  public readonly feedReader: FeedReaderApi;
  public readonly gameCategorySettings: GameCategorySettingsApi;
  public readonly games: GamesApi;
  public readonly gamesCategories: GamesCategoriesApi;
  public readonly gamesSettings: GamesSettingsApi;
  public readonly healthcheck: HealthcheckApi;
  public readonly languages: LanguagesApi;
  public readonly promo: PromoApi;
  public readonly promoDeposits: PromoDepositsApi;
  public readonly promoLogs: PromoLogsApi;
  public readonly promoNominations: PromoNominationsApi;
  public readonly providerConfigs: ProviderConfigsApi;
  public readonly users: UsersApi;
  public readonly request: BaseHttpRequest;
  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? 'local_version',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });
    this.apiKeys = new ApiKeysApi(this.request);
    this.auth = new AuthApi(this.request);
    this.categoriesSettings = new CategoriesSettingsApi(this.request);
    this.categoryPresets = new CategoryPresetsApi(this.request);
    this.changelogs = new ChangelogsApi(this.request);
    this.feedReader = new FeedReaderApi(this.request);
    this.gameCategorySettings = new GameCategorySettingsApi(this.request);
    this.games = new GamesApi(this.request);
    this.gamesCategories = new GamesCategoriesApi(this.request);
    this.gamesSettings = new GamesSettingsApi(this.request);
    this.healthcheck = new HealthcheckApi(this.request);
    this.languages = new LanguagesApi(this.request);
    this.promo = new PromoApi(this.request);
    this.promoDeposits = new PromoDepositsApi(this.request);
    this.promoLogs = new PromoLogsApi(this.request);
    this.promoNominations = new PromoNominationsApi(this.request);
    this.providerConfigs = new ProviderConfigsApi(this.request);
    this.users = new UsersApi(this.request);
  }
}

