import { ref } from 'vue';
import { getStorageData, setStorageData } from '@/lib/local-storage';

const CACHE_KEY = 'menu_collapsed';

export const useSidebarPosition = () => {
  const isCollapsed = ref(getStorageData<boolean>(CACHE_KEY) ?? false);
  const toggleCollapse = () => {
    isCollapsed.value = !isCollapsed.value;
    setStorageData(CACHE_KEY, isCollapsed.value);
  };

  return {
    isCollapsed,
    toggleCollapse,
  } as const;
};
