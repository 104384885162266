import { VueQueryPluginOptions } from '@tanstack/vue-query';

export const vueQueryConfig: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: 2,
        staleTime: Number.POSITIVE_INFINITY,
        refetchInterval: Number.POSITIVE_INFINITY,
      },
    },
  },
};
