/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GameOfWeekImageCompressErrorResponse = {
  message: string;
  code: GameOfWeekImageCompressErrorResponse.code;
  /**
   * Name of the image
   */
  data: string;
};
export namespace GameOfWeekImageCompressErrorResponse {
  export enum code {
    ERROR_IMAGE_COMPRESS_FAILED = 'error.image.compress-failed',
  }
}

