import { defineStore } from 'pinia';
import { client, RIGHT } from '@/api';
import { resetRouter } from '@/router';

const DEFAULT_AVATAR_URL = 'https://pngimg.com/uploads/roulette/roulette_PNG12.png';

type UserInfo = {
  id: number | null;
  email: string | null;
  isSuperUser?: boolean;
}

const getInitialInfo = (): UserInfo => ({
  id: null,
  email: null,
});

export const useUserStore = defineStore('user', {
  state: () => ({
    avatar: DEFAULT_AVATAR_URL,
    userInfoFetched: false,
    info: getInitialInfo(),
    rights: [] as RIGHT[],
    defaultLocaleId: -1,
  }),
  actions: {
    async login({ email, password }: { email: string, password: string }) {
      await client.auth.loginLocal({ requestBody: { email, password } });
    },

    async logout() {
      await client.auth.logout();
      this.resetUser();
    },

    async fetchUserInfo() {
      const info = await client.users.getSelfInfo();

      if (!info?.id || info.rights === undefined) {
        this.resetUser();
        throw new Error('Invalid user info');
      }

      this.info = {
        id: info.id,
        email: info.email ?? null,
      };

      if (info.isSuperUser === true) {
        this.info.isSuperUser = true;
      }

      this.avatar = DEFAULT_AVATAR_URL;
      this.userInfoFetched = true;
      this.rights = Array.isArray(info.rights) === true
        ? info.rights
        : [];

      this.defaultLocaleId = info.defaultLocaleId;

      return info;
    },

    resetUser() {
      this.info = getInitialInfo();
      this.avatar = '';
      this.userInfoFetched = false;
      this.rights = [];
      this.defaultLocaleId = -1;
      resetRouter();
    },
  },
});
