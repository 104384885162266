/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddNominationDto } from '../models/AddNominationDto';
import type { AddNominationResponse } from '../models/AddNominationResponse';
import type { CreateOrUpdatePromoResponse } from '../models/CreateOrUpdatePromoResponse';
import type { CreatePromoDto } from '../models/CreatePromoDto';
import type { GetManyPromoEntityResponseDto } from '../models/GetManyPromoEntityResponseDto';
import type { PromoEntity } from '../models/PromoEntity';
import type { PromoEventResponse } from '../models/PromoEventResponse';
import type { PromoParamResponse } from '../models/PromoParamResponse';
import type { PromoStatisticsResponse } from '../models/PromoStatisticsResponse';
import type { UpdatePromoDto } from '../models/UpdatePromoDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PromoApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get promo statistics
   * @returns PromoStatisticsResponse
   * @throws ApiError
   */
  public getStatistics({
    promoId,
  }: {
    promoId: number,
  }): CancelablePromise<PromoStatisticsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/promo/statistics',
      query: {
        'promoId': promoId,
      },
      errors: {
        404: `Promo not found`,
      },
    });
  }
  /**
   * Get all promo params
   * @returns any
   * @throws ApiError
   */
  public getAllPromoParams(): CancelablePromise<{
    promoMaxWinAmount: PromoParamResponse;
    promoMaxWinCurrency: PromoParamResponse;
    promoMaxUsersLimit: PromoParamResponse;
    promoMaxFreespinsLimit: PromoParamResponse;
    promoDepositTtlInMinutes: PromoParamResponse;
    spinCurrency: PromoParamResponse;
    spinAmount: PromoParamResponse;
    freeSpinCount: PromoParamResponse;
    freeSpinTtlInMinutes: PromoParamResponse;
    userMaxWinLimit: PromoParamResponse;
    gameCategoriesIds: PromoParamResponse;
    gameProvider: PromoParamResponse;
    gameIds: PromoParamResponse;
    betLevel: PromoParamResponse;
    wager: PromoParamResponse;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/promo/params',
    });
  }
  /**
   * Get all promo events
   * @returns PromoEventResponse
   * @throws ApiError
   */
  public getAllPromoEvents(): CancelablePromise<Array<PromoEventResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/promo/events',
    });
  }
  /**
   * Create a promo
   * @returns CreateOrUpdatePromoResponse
   * @throws ApiError
   */
  public createPromo({
    requestBody,
  }: {
    requestBody: CreatePromoDto,
  }): CancelablePromise<CreateOrUpdatePromoResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/promo',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Retrieve multiple promo
   * @returns any Get many base response
   * @throws ApiError
   */
  public getPromoList({
    fields,
    s,
    filter,
    or,
    sort,
    join,
    limit,
    offset,
    page,
    cache,
  }: {
    /**
     * Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a>
     */
    fields?: Array<string>,
    /**
     * Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a>
     */
    s?: string,
    /**
     * Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a>
     */
    filter?: Array<string>,
    /**
     * Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a>
     */
    or?: Array<string>,
    /**
     * Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a>
     */
    sort?: Array<string>,
    /**
     * Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a>
     */
    join?: Array<string>,
    /**
     * Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a>
     */
    limit?: number,
    /**
     * Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a>
     */
    offset?: number,
    /**
     * Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a>
     */
    page?: number,
    /**
     * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
     */
    cache?: number,
  }): CancelablePromise<(GetManyPromoEntityResponseDto | Array<PromoEntity>)> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/promo',
      query: {
        'fields': fields,
        's': s,
        'filter': filter,
        'or': or,
        'sort': sort,
        'join': join,
        'limit': limit,
        'offset': offset,
        'page': page,
        'cache': cache,
      },
    });
  }
  /**
   * Add a user to the nomination
   * @returns AddNominationResponse
   * @throws ApiError
   */
  public addNomination({
    requestBody,
  }: {
    requestBody: AddNominationDto,
  }): CancelablePromise<AddNominationResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/promo/addNomination',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Upload user id list to manual assign
   * @returns CreateOrUpdatePromoResponse
   * @throws ApiError
   */
  public uploadUserIdList({
    id,
    formData,
  }: {
    id: number,
    formData: {
      /**
       * File
       */
      file: Blob;
    },
  }): CancelablePromise<CreateOrUpdatePromoResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/promo/upload-user-id-list/{id}',
      path: {
        'id': id,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
  /**
   * Update the promo
   * @returns CreateOrUpdatePromoResponse
   * @throws ApiError
   */
  public updatePromo({
    id,
    requestBody,
  }: {
    id: number,
    requestBody: UpdatePromoDto,
  }): CancelablePromise<CreateOrUpdatePromoResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/promo/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Promo not found`,
      },
    });
  }
  /**
   * @returns boolean Is promo deleted?
   * @throws ApiError
   */
  public deletePromo({
    id,
  }: {
    /**
     * Promo ID
     */
    id: number,
  }): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/promo/{id}',
      path: {
        'id': id,
      },
      errors: {
        400: `Promo has nominations`,
      },
    });
  }
  /**
   * Retrieve a single promo
   * @returns PromoEntity Get one base response
   * @throws ApiError
   */
  public getOnePromo({
    id,
    fields,
    join,
    cache,
  }: {
    id: number,
    /**
     * Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a>
     */
    fields?: Array<string>,
    /**
     * Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a>
     */
    join?: Array<string>,
    /**
     * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
     */
    cache?: number,
  }): CancelablePromise<PromoEntity> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/promo/{id}',
      path: {
        'id': id,
      },
      query: {
        'fields': fields,
        'join': join,
        'cache': cache,
      },
    });
  }
}
