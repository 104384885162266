/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CopyByIdDto } from '../models/CopyByIdDto';
import type { CountResponse } from '../models/CountResponse';
import type { CreateGameCategorySettingsDto } from '../models/CreateGameCategorySettingsDto';
import type { CreateManyGameCategorySettingsEntityDto } from '../models/CreateManyGameCategorySettingsEntityDto';
import type { GameCategorySettingsEntity } from '../models/GameCategorySettingsEntity';
import type { GetManyGameCategorySettingsEntityResponseDto } from '../models/GetManyGameCategorySettingsEntityResponseDto';
import type { UpdateGameCategorySettingsDto } from '../models/UpdateGameCategorySettingsDto';
import type { UpdateManyPositionDto } from '../models/UpdateManyPositionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class GameCategorySettingsApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns CountResponse
   * @throws ApiError
   */
  public copyGameCategorySettings({
    presetId,
    requestBody,
  }: {
    presetId: number,
    requestBody: CopyByIdDto,
  }): CancelablePromise<CountResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/game-category-settings/preset/{presetId}/copy',
      path: {
        'presetId': presetId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns CountResponse
   * @throws ApiError
   */
  public updateManyPosition({
    requestBody,
  }: {
    requestBody: UpdateManyPositionDto,
  }): CancelablePromise<CountResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/game-category-settings/update-many-position',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Retrieve multiple category settings
   * @returns any Get many base response
   * @throws ApiError
   */
  public getGameCategorySettings({
    fields,
    s,
    filter,
    or,
    sort,
    join,
    limit,
    offset,
    page,
    cache,
  }: {
    /**
     * Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a>
     */
    fields?: Array<string>,
    /**
     * Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a>
     */
    s?: string,
    /**
     * Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a>
     */
    filter?: Array<string>,
    /**
     * Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a>
     */
    or?: Array<string>,
    /**
     * Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a>
     */
    sort?: Array<string>,
    /**
     * Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a>
     */
    join?: Array<string>,
    /**
     * Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a>
     */
    limit?: number,
    /**
     * Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a>
     */
    offset?: number,
    /**
     * Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a>
     */
    page?: number,
    /**
     * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
     */
    cache?: number,
  }): CancelablePromise<(GetManyGameCategorySettingsEntityResponseDto | Array<GameCategorySettingsEntity>)> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/game-category-settings',
      query: {
        'fields': fields,
        's': s,
        'filter': filter,
        'or': or,
        'sort': sort,
        'join': join,
        'limit': limit,
        'offset': offset,
        'page': page,
        'cache': cache,
      },
    });
  }
  /**
   * Create a single category locale settings
   * @returns GameCategorySettingsEntity Get create one base response
   * @throws ApiError
   */
  public createOneGameCategorySettings({
    requestBody,
  }: {
    requestBody: CreateGameCategorySettingsDto,
  }): CancelablePromise<GameCategorySettingsEntity> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/game-category-settings',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Create a multiple category locale settings
   * @returns GameCategorySettingsEntity Get create many base response
   * @throws ApiError
   */
  public createManyGameCategorySettings({
    requestBody,
  }: {
    requestBody: CreateManyGameCategorySettingsEntityDto,
  }): CancelablePromise<Array<GameCategorySettingsEntity>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/game-category-settings/bulk',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Update a single category locale settings
   * @returns GameCategorySettingsEntity Response
   * @throws ApiError
   */
  public updateOneGameCategorySettings({
    categoryId,
    localeId,
    presetId,
    requestBody,
  }: {
    categoryId: number,
    localeId: number,
    presetId: number,
    requestBody: UpdateGameCategorySettingsDto,
  }): CancelablePromise<GameCategorySettingsEntity> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/game-category-settings/{categoryId}/{localeId}/{presetId}',
      path: {
        'categoryId': categoryId,
        'localeId': localeId,
        'presetId': presetId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Delete a single category locale settings
   * @returns any Delete one base response
   * @throws ApiError
   */
  public deleteOneGameCategorySettings({
    categoryId,
    localeId,
    presetId,
  }: {
    categoryId: number,
    localeId: number,
    presetId: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/game-category-settings/{categoryId}/{localeId}/{presetId}',
      path: {
        'categoryId': categoryId,
        'localeId': localeId,
        'presetId': presetId,
      },
    });
  }
}
