/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfigProvider } from '../models/ConfigProvider';
import type { GetManyProviderConfigEntityResponseDto } from '../models/GetManyProviderConfigEntityResponseDto';
import type { ProviderConfig } from '../models/ProviderConfig';
import type { ProviderConfigEntity } from '../models/ProviderConfigEntity';
import type { UpdateConfigValueDto } from '../models/UpdateConfigValueDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ProviderConfigsApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get providers with configs
   * @returns ConfigProvider
   * @throws ApiError
   */
  public getProvidersConfigs(): CancelablePromise<Array<ConfigProvider>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/provider-configs/providers',
    });
  }
  /**
   * Get configs for single provider
   * @returns ProviderConfig
   * @throws ApiError
   */
  public getProviderConfigs({
    providerKey,
  }: {
    providerKey: string,
  }): CancelablePromise<Array<ProviderConfig>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/provider-configs/providers/{providerKey}/configs',
      path: {
        'providerKey': providerKey,
      },
    });
  }
  /**
   * Create config value
   * @returns ProviderConfig
   * @throws ApiError
   */
  public createConfigValue({
    providerKey,
    configKey,
    requestBody,
  }: {
    providerKey: string,
    configKey: string,
    requestBody: UpdateConfigValueDto,
  }): CancelablePromise<ProviderConfig> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/provider-configs/providers/{providerKey}/configs/{configKey}',
      path: {
        'providerKey': providerKey,
        'configKey': configKey,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Update config value
   * @returns ProviderConfig
   * @throws ApiError
   */
  public updateConfigValue({
    id,
    requestBody,
  }: {
    id: number,
    requestBody: UpdateConfigValueDto,
  }): CancelablePromise<ProviderConfig> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/provider-configs/configs/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Retrieve a single config
   * @returns ProviderConfigEntity Get one base response
   * @throws ApiError
   */
  public getOneConfig({
    id,
    fields,
    join,
    cache,
  }: {
    id: number,
    /**
     * Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a>
     */
    fields?: Array<string>,
    /**
     * Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a>
     */
    join?: Array<string>,
    /**
     * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
     */
    cache?: number,
  }): CancelablePromise<ProviderConfigEntity> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/provider-configs/{id}',
      path: {
        'id': id,
      },
      query: {
        'fields': fields,
        'join': join,
        'cache': cache,
      },
    });
  }
  /**
   * Retrieve multiple configs
   * @returns any Get many base response
   * @throws ApiError
   */
  public getConfigs({
    fields,
    s,
    filter,
    or,
    sort,
    join,
    limit,
    offset,
    page,
    cache,
  }: {
    /**
     * Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a>
     */
    fields?: Array<string>,
    /**
     * Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a>
     */
    s?: string,
    /**
     * Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a>
     */
    filter?: Array<string>,
    /**
     * Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a>
     */
    or?: Array<string>,
    /**
     * Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a>
     */
    sort?: Array<string>,
    /**
     * Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a>
     */
    join?: Array<string>,
    /**
     * Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a>
     */
    limit?: number,
    /**
     * Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a>
     */
    offset?: number,
    /**
     * Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a>
     */
    page?: number,
    /**
     * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
     */
    cache?: number,
  }): CancelablePromise<(GetManyProviderConfigEntityResponseDto | Array<ProviderConfigEntity>)> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/provider-configs',
      query: {
        'fields': fields,
        's': s,
        'filter': filter,
        'or': or,
        'sort': sort,
        'join': join,
        'limit': limit,
        'offset': offset,
        'page': page,
        'cache': cache,
      },
    });
  }
}
