/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvailableProvidersResponse } from '../models/AvailableProvidersResponse';
import type { CreateGameDto } from '../models/CreateGameDto';
import type { CreateGameOfWeekDto } from '../models/CreateGameOfWeekDto';
import type { CreateGameResponse } from '../models/CreateGameResponse';
import type { GAME_IMAGE_ORIENTATION } from '../models/GAME_IMAGE_ORIENTATION';
import type { GameEntity } from '../models/GameEntity';
import type { GameOfWeekEntity } from '../models/GameOfWeekEntity';
import type { GameOfWeekFormattedResponse } from '../models/GameOfWeekFormattedResponse';
import type { GameOfWeekFullResponse } from '../models/GameOfWeekFullResponse';
import type { GameOfWeekIdResponse } from '../models/GameOfWeekIdResponse';
import type { GameOfWeekLocaleEntity } from '../models/GameOfWeekLocaleEntity';
import type { GameSessionDataResponse } from '../models/GameSessionDataResponse';
import type { GameSessionListItemResponse } from '../models/GameSessionListItemResponse';
import type { GetGameLaunchUrlDto } from '../models/GetGameLaunchUrlDto';
import type { GetGamesVaixResponse } from '../models/GetGamesVaixResponse';
import type { GetManyGameEntityResponseDto } from '../models/GetManyGameEntityResponseDto';
import type { LinkLocaleToGameOfWeekDto } from '../models/LinkLocaleToGameOfWeekDto';
import type { LocaleResponse } from '../models/LocaleResponse';
import type { ManualProvidersResponse } from '../models/ManualProvidersResponse';
import type { PROVIDER } from '../models/PROVIDER';
import type { PROVIDER_LOGO_SIZE } from '../models/PROVIDER_LOGO_SIZE';
import type { ProviderSettingsEntity } from '../models/ProviderSettingsEntity';
import type { RenameSubprovidersDto } from '../models/RenameSubprovidersDto';
import type { SubproviderResponse } from '../models/SubproviderResponse';
import type { UpdateGameDto } from '../models/UpdateGameDto';
import type { UpdateGameImageResponse } from '../models/UpdateGameImageResponse';
import type { UpdateGameOfWeekDto } from '../models/UpdateGameOfWeekDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class GamesApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get game locales
   * @returns LocaleResponse
   * @throws ApiError
   */
  public getLocales(): CancelablePromise<Array<LocaleResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games/locales',
    });
  }
  /**
   * Get providers with manual game creation
   * @returns ManualProvidersResponse
   * @throws ApiError
   */
  public getProvidersWithManualGameCreation(): CancelablePromise<ManualProvidersResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games/providers/manual',
    });
  }
  /**
   * Get games for vaix integration
   * @returns GetGamesVaixResponse
   * @throws ApiError
   */
  public getGamesForVaixIntegration(): CancelablePromise<GetGamesVaixResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games/vaix',
    });
  }
  /**
   * Get game owners for selected providers
   * @returns SubproviderResponse
   * @throws ApiError
   */
  public getSubproviders({
    p,
  }: {
    p?: Array<PROVIDER>,
  }): CancelablePromise<Array<SubproviderResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games/providers/game-owners',
      query: {
        'p': p,
      },
    });
  }
  /**
   * Get available providers
   * @returns AvailableProvidersResponse
   * @throws ApiError
   */
  public getAvailableProviders(): CancelablePromise<AvailableProvidersResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games/providers/available',
    });
  }
  /**
   * @returns ProviderSettingsEntity
   * @throws ApiError
   */
  public getSubprovidersSettings({
    providerId,
    localeId,
  }: {
    providerId?: string,
    localeId?: number,
  }): CancelablePromise<Array<ProviderSettingsEntity>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games/providers/game-owners/settings',
      query: {
        'providerId': providerId,
        'localeId': localeId,
      },
    });
  }
  /**
   * @returns string
   * @throws ApiError
   */
  public getSubprovidersDisplayNames({
    provider,
    localeId,
    withFallbackToDefaultLocale,
  }: {
    provider: PROVIDER,
    localeId?: number,
    /**
     * If `true` and display name for provided `localeId` is not set, will return display name for default locale
     */
    withFallbackToDefaultLocale?: boolean,
  }): CancelablePromise<Record<string, string | null>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games/providers/game-owners/display-names',
      query: {
        'provider': provider,
        'localeId': localeId,
        'withFallbackToDefaultLocale': withFallbackToDefaultLocale,
      },
    });
  }
  /**
   * @returns string
   * @throws ApiError
   */
  public changeSubprovidersDisplayNames({
    requestBody,
  }: {
    requestBody: RenameSubprovidersDto,
  }): CancelablePromise<Record<string, string | null>> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/games/providers/game-owners/display-names',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns ProviderSettingsEntity
   * @throws ApiError
   */
  public changeSubproviderLogo({
    providerId,
    subproviderId,
    formData,
    localeId,
    size,
  }: {
    providerId: string,
    subproviderId: string,
    formData: {
      /**
       * File
       */
      image: Blob;
    },
    localeId?: number,
    size?: PROVIDER_LOGO_SIZE,
  }): CancelablePromise<ProviderSettingsEntity> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/games/providers/game-owners/image',
      query: {
        'providerId': providerId,
        'subproviderId': subproviderId,
        'localeId': localeId,
        'size': size,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
  /**
   * Change image for game in locale
   * @returns UpdateGameImageResponse
   * @throws ApiError
   */
  public updateGameImage({
    gameId,
    localeId,
    formData,
    isCompressionEnabled,
    orientation,
  }: {
    gameId: string,
    localeId: number,
    formData: {
      /**
       * File
       */
      image: Blob;
    },
    isCompressionEnabled?: boolean,
    orientation?: GAME_IMAGE_ORIENTATION,
  }): CancelablePromise<UpdateGameImageResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/games/image',
      query: {
        'gameId': gameId,
        'localeId': localeId,
        'isCompressionEnabled': isCompressionEnabled,
        'orientation': orientation,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
  /**
   * Returns URL for game launch
   * @returns string Game launch URL
   * @throws ApiError
   */
  public getGameLaunchUrl({
    requestBody,
  }: {
    requestBody: GetGameLaunchUrlDto,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/games/get-game-launch-url',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Returns a list of user IDs for which the game URL can be generated
   * @returns number Array of user IDs
   * @throws ApiError
   */
  public getUserIdsForGameLaunchUrl(): CancelablePromise<Array<number>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games/users-for-game-launch-url',
    });
  }
  /**
   * Returns the game session (aka wallet session) data
   * @returns GameSessionDataResponse
   * @throws ApiError
   */
  public getGameSessionData({
    sessionId,
  }: {
    sessionId: string,
  }): CancelablePromise<GameSessionDataResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games/sessions/{sessionId}',
      path: {
        'sessionId': sessionId,
      },
    });
  }
  /**
   * @returns GameSessionListItemResponse
   * @throws ApiError
   */
  public getGameSessions({
    provider,
    userId,
    gameId,
  }: {
    provider: PROVIDER,
    userId: number,
    gameId?: string,
  }): CancelablePromise<Array<GameSessionListItemResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games/sessions',
      query: {
        'provider': provider,
        'userId': userId,
        'gameId': gameId,
      },
    });
  }
  /**
   * Returns the list of raw games of week from DB
   * @returns GameOfWeekEntity
   * @throws ApiError
   */
  public getGameOfWeekList({
    gameId,
    localeIds,
    page = 1,
    limit,
    fields,
  }: {
    gameId?: string,
    /**
     * Locale IDs in which provided game will be set as a game of week. Each locale has its own game of week (one game per locale). The list of actual locale IDs can be found in the "/games/locales" endpoint. The default game of week suitable for all unspecified locales should be stored in the default locale related to tenant).
     */
    localeIds?: Array<number>,
    /**
     * Page number, starts from 1. Example: for limit=10 and page=1 the result will be [1-10] items; for limit=10 and page=2 the result will be [11-20] items.
     */
    page?: number,
    /**
     * Number of items per page. If not provided, all items will be returned.
     */
    limit?: number,
    /**
     * fields to select
     */
    fields?: Array<'id' | 'gameId' | 'imageUrl' | 'nameTranslations' | 'createdAt' | 'updatedAt' | 'locales'>,
  }): CancelablePromise<Array<GameOfWeekEntity>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games/game-of-week',
      query: {
        'gameId': gameId,
        'localeIds': localeIds,
        'page': page,
        'limit': limit,
        'fields': fields,
      },
    });
  }
  /**
   * @returns GameOfWeekEntity
   * @throws ApiError
   */
  public createGameOfWeek({
    formData,
  }: {
    formData: CreateGameOfWeekDto,
  }): CancelablePromise<GameOfWeekEntity> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/games/game-of-week',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
  /**
   * Returns the list of formatted games of week
   * @returns GameOfWeekFormattedResponse
   * @throws ApiError
   */
  public getGameOfWeekListFormatted({
    gameId,
    localeIds,
    page = 1,
    limit,
  }: {
    gameId?: string,
    /**
     * Locale IDs in which provided game will be set as a game of week. Each locale has its own game of week (one game per locale). The list of actual locale IDs can be found in the "/games/locales" endpoint. The default game of week suitable for all unspecified locales should be stored in the default locale related to tenant).
     */
    localeIds?: Array<number>,
    /**
     * Page number, starts from 1. Example: for limit=10 and page=1 the result will be [1-10] items; for limit=10 and page=2 the result will be [11-20] items.
     */
    page?: number,
    /**
     * Number of items per page. If not provided, all items will be returned.
     */
    limit?: number,
  }): CancelablePromise<Array<GameOfWeekFormattedResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games/game-of-week/formatted',
      query: {
        'gameId': gameId,
        'localeIds': localeIds,
        'page': page,
        'limit': limit,
      },
    });
  }
  /**
   * Returns the total number of games of week
   * @returns number
   * @throws ApiError
   */
  public getGamesOfWeekTotalCount({
    gameId,
    localeIds,
  }: {
    gameId?: string,
    /**
     * Locale IDs in which provided game will be set as a game of week. Each locale has its own game of week (one game per locale). The list of actual locale IDs can be found in the "/games/locales" endpoint. The default game of week suitable for all unspecified locales should be stored in the default locale related to tenant).
     */
    localeIds?: Array<number>,
  }): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games/game-of-week/count',
      query: {
        'gameId': gameId,
        'localeIds': localeIds,
      },
    });
  }
  /**
   * Returns full formatted data for the game of week
   * @returns GameOfWeekFullResponse
   * @throws ApiError
   */
  public getGameOfWeekFullData({
    id,
  }: {
    /**
     * Game of week entity ID
     */
    id: number,
  }): CancelablePromise<GameOfWeekFullResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games/game-of-week/{id}',
      path: {
        'id': id,
      },
    });
  }
  /**
   * @returns GameOfWeekEntity
   * @throws ApiError
   */
  public updateGameOfWeek({
    id,
    formData,
  }: {
    /**
     * Game of week entity ID
     */
    id: number,
    formData: UpdateGameOfWeekDto,
  }): CancelablePromise<GameOfWeekEntity> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/games/game-of-week/{id}',
      path: {
        'id': id,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
  /**
   * Deletes the game of week entity
   * @returns GameOfWeekIdResponse
   * @throws ApiError
   */
  public deleteGameOfWeek({
    id,
  }: {
    /**
     * Game of week entity ID
     */
    id: number,
  }): CancelablePromise<GameOfWeekIdResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/games/game-of-week/{id}',
      path: {
        'id': id,
      },
    });
  }
  /**
   * Links the game of week with the locale
   * @returns GameOfWeekLocaleEntity
   * @throws ApiError
   */
  public linkGameOfWeekToLocale({
    requestBody,
  }: {
    requestBody: LinkLocaleToGameOfWeekDto,
  }): CancelablePromise<GameOfWeekLocaleEntity> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/games/game-of-week/locales',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Unlinks a game of week from provided locale
   * @returns GameOfWeekLocaleEntity
   * @throws ApiError
   */
  public unlinkGameOfWeekFromLocale({
    localeId,
  }: {
    localeId: number,
  }): CancelablePromise<GameOfWeekLocaleEntity> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/games/game-of-week/locales/{localeId}',
      path: {
        'localeId': localeId,
      },
    });
  }
  /**
   * @returns GameEntity
   * @throws ApiError
   */
  public updateGame({
    id,
    requestBody,
  }: {
    /**
     * Game ID
     */
    id: string,
    requestBody: UpdateGameDto,
  }): CancelablePromise<GameEntity> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/games/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Retrieve a single game
   * @returns GameEntity Get one base response
   * @throws ApiError
   */
  public getOneGame({
    id,
    fields,
    join,
    cache,
  }: {
    id: string,
    /**
     * Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a>
     */
    fields?: Array<string>,
    /**
     * Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a>
     */
    join?: Array<string>,
    /**
     * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
     */
    cache?: number,
  }): CancelablePromise<GameEntity> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games/{id}',
      path: {
        'id': id,
      },
      query: {
        'fields': fields,
        'join': join,
        'cache': cache,
      },
    });
  }
  /**
   * Create game
   * @returns CreateGameResponse
   * @throws ApiError
   */
  public createGame({
    requestBody,
  }: {
    requestBody: CreateGameDto,
  }): CancelablePromise<CreateGameResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/games',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Retrieve multiple games
   * @returns any Get many base response
   * @throws ApiError
   */
  public getGames({
    fields,
    s,
    filter,
    or,
    sort,
    join,
    limit,
    offset,
    page,
    cache,
  }: {
    /**
     * Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a>
     */
    fields?: Array<string>,
    /**
     * Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a>
     */
    s?: string,
    /**
     * Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a>
     */
    filter?: Array<string>,
    /**
     * Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a>
     */
    or?: Array<string>,
    /**
     * Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a>
     */
    sort?: Array<string>,
    /**
     * Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a>
     */
    join?: Array<string>,
    /**
     * Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a>
     */
    limit?: number,
    /**
     * Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a>
     */
    offset?: number,
    /**
     * Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a>
     */
    page?: number,
    /**
     * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
     */
    cache?: number,
  }): CancelablePromise<(GetManyGameEntityResponseDto | Array<GameEntity>)> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games',
      query: {
        'fields': fields,
        's': s,
        'filter': filter,
        'or': or,
        'sort': sort,
        'join': join,
        'limit': limit,
        'offset': offset,
        'page': page,
        'cache': cache,
      },
    });
  }
}
