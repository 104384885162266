/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PromoDepositEntity } from './PromoDepositEntity';
import type { PromoNominationEntity } from './PromoNominationEntity';
export type PromoEntity = {
  createdAt: string;
  updatedAt: string;
  id: number;
  title: string;
  events: string;
  eventsConditions: string;
  params: string;
  status: PromoEntity.status;
  isActive: boolean;
  isTest: boolean;
  isManual: boolean;
  showInCashier: boolean;
  startsAt: string;
  endsAt: string;
  nominations?: Array<PromoNominationEntity>;
  deposits?: Array<PromoDepositEntity>;
};
export namespace PromoEntity {
  export enum status {
    TEMPORARY = 'temporary',
    ACTIVE = 'active',
    EXPIRED = 'expired',
    LIMIT_EXCEEDED = 'limitExceeded',
    MANUALLY_STOPPED = 'manuallyStopped',
    DONE = 'done',
  }
}

