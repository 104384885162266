/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GameOfWeekWrongLocalesResponse = {
  message: Record<string, any>;
  code: GameOfWeekWrongLocalesResponse.code;
  data: Array<number>;
};
export namespace GameOfWeekWrongLocalesResponse {
  export enum code {
    ERROR_LOCALE_NOT_FOUND = 'error.locale.not-found',
  }
}

