export const string = (value: string) => value;

export const float = (value: string) => {
  const parsed = parseFloat(value);

  if (Number.isNaN(parsed)) {
    throw new Error('value is NaN');
  }

  return parsed;
};

export const int = (value: string) => {
  const parsed = parseInt(value, 10);

  if (Number.isNaN(parsed)) {
    throw new Error('value is NaN');
  }

  return parsed;
};

export type ConfigValueParser<T> = (value: string) => T;

export type fromEnvArgs<T> = {
  key: string;
  defaultValue?: T;
  parser: ConfigValueParser<T>,
}

export const fromEnv = <T>(a: fromEnvArgs<T>): T => {
  const { key, defaultValue, parser } = a;

  // eslint-disable-next-line no-process-env
  const envValue = window.injectedEnv !== undefined ? window.injectedEnv[a.key] : process.env[a.key];

  if (envValue === undefined && defaultValue !== undefined) {
    return defaultValue;
  }

  if (envValue === undefined) {
    if (defaultValue !== undefined) {
      return defaultValue;
    }

    throw new Error(`[vue-dynamic-config] field '${key}' is not present and has no default value`);
  }

  try {
    const parsedValue = parser(envValue);
    return parsedValue;
  } catch (e) {
    throw new Error(`[vue-dynamic-config] value parsing error. key '${key}', value '${envValue}'`);
  }
};
