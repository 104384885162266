export enum ROUTE {
  LOGIN = 'login',
  DASHBOARD = 'dashboard',
  PROFILE = 'profile',
  CHANGELOGS = 'changelogs',
  CHANGELOG = 'changelog',
  USERS = 'users',
  GAMES = 'games',
  GAME_NEW = 'game_new',
  GAME_EDIT = 'game_edit',
  GAME_LAUNCH_URL = 'game_launch_url',
  GAME_OF_WEEK_LIST = 'game_of_week_list',
  GAME_OF_WEEK_NEW = 'game_of_week_new',
  GAME_OF_WEEK_EDIT = 'game_of_week_edit',
  GAME_CATEGORIES_WRAP = 'game_categories_wrap',
  GAME_CATEGORIES = 'game_categories',
  PROMO = 'promo',
  ONE_PROMO = 'one_promo',
  PROMO_NOMINATIONS = 'promo-nominations',
  PROMO_DEPOSITS = 'promo-deposits',
  PROMO_LOGS = 'promo-logs',
  PROMO_STATISTICS = 'promo-statistics',
  PROVIDER_GAME_CONFIGS = 'provider_game_configs',
  PROVIDER_GAME_CONFIG = 'provider_game_config',
  PROVIDER_GAMES_LOGS = 'provider_games_logs',
  PROVIDER_API_CALL = 'provider_api_call',
  API_KEYS = 'api_keys',
  NEW_API_KEY = 'new_api_key',
  PROVIDER_GAME_SESSION = 'provider_games_session',
  PROVIDER_GAME_SESSIONS = 'provider_games_sessions',
  PROVIDER_DISPLAY_NAMES = 'provider_display_names',
  PROVIDER_SETTINGS = 'provider_settings',
  WIDGETS = 'widgets',
  ERR_404 = '404',
  ERR_500 = '500',
}
