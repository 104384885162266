import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as ElementIcons from '@element-plus/icons-vue';
import { VueQueryPlugin } from '@tanstack/vue-query';

import * as piniaStore from '@/store';
import App from './App.vue';
import { createRouter } from './router';
import { vueQueryConfig } from './config/vue-query.config';

import 'normalize.css';
import 'element-plus/es/components/message/style/css';
import 'element-plus/es/components/message-box/style/css';
import '@/styles/index.sass';

const main = () => {
  const pinia = createPinia();

  const router = createRouter(piniaStore);

  const app = createApp(App)
    .use(VueQueryPlugin, vueQueryConfig)
    .use(pinia)
    .use(router);

  for (const iconKey of Object.keys(ElementIcons)) {
    // @ts-expect-error разрабы либы не вывели удобно тип
    app.component(ElementIcons[iconKey].name, ElementIcons[iconKey]);
  }

  app.mount('#app');
};
main();
