/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CategoryBaseResponse } from '../models/CategoryBaseResponse';
import type { CategoryForRandomGameEntity } from '../models/CategoryForRandomGameEntity';
import type { GameCategoryEntity } from '../models/GameCategoryEntity';
import type { GetManyGameCategoryEntityResponseDto } from '../models/GetManyGameCategoryEntityResponseDto';
import type { SaveRandomCategoryDto } from '../models/SaveRandomCategoryDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class GamesCategoriesApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns CategoryBaseResponse
   * @throws ApiError
   */
  public getGameCategoriesBase(): CancelablePromise<Array<CategoryBaseResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games/categories/base',
    });
  }
  /**
   * @returns CategoryForRandomGameEntity
   * @throws ApiError
   */
  public getOneCategoryForRandomGame({
    localeId,
  }: {
    localeId?: number,
  }): CancelablePromise<CategoryForRandomGameEntity> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games/categories/random/one',
      query: {
        'localeId': localeId,
      },
    });
  }
  /**
   * Saves the category from which the random game will be selected
   * @returns CategoryForRandomGameEntity
   * @throws ApiError
   */
  public saveCategoryForRandomGame({
    requestBody,
  }: {
    requestBody: SaveRandomCategoryDto,
  }): CancelablePromise<CategoryForRandomGameEntity> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/games/categories/random',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Upload a new image for category in locale
   * @returns string Image uploaded for category in locale
   * @throws ApiError
   */
  public uploadCategoryImage({
    formData,
  }: {
    formData: {
      /**
       * New image
       */
      image: Blob;
      categoryId: number;
      localeId: number;
      presetId?: number;
      orientation?: 'horizontal' | 'vertical';
      isCompressionEnabled?: boolean;
    },
  }): CancelablePromise<string | null> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/games/categories/image',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
  /**
   * Retrieve a single game category
   * @returns GameCategoryEntity Get one base response
   * @throws ApiError
   */
  public getOneGameCategory({
    id,
    fields,
    join,
    cache,
  }: {
    id: number,
    /**
     * Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a>
     */
    fields?: Array<string>,
    /**
     * Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a>
     */
    join?: Array<string>,
    /**
     * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
     */
    cache?: number,
  }): CancelablePromise<GameCategoryEntity> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games/categories/{id}',
      path: {
        'id': id,
      },
      query: {
        'fields': fields,
        'join': join,
        'cache': cache,
      },
    });
  }
  /**
   * Retrieve multiple game categories
   * @returns any Get many base response
   * @throws ApiError
   */
  public getGameCategories({
    fields,
    s,
    filter,
    or,
    sort,
    join,
    limit,
    offset,
    page,
    cache,
  }: {
    /**
     * Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a>
     */
    fields?: Array<string>,
    /**
     * Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a>
     */
    s?: string,
    /**
     * Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a>
     */
    filter?: Array<string>,
    /**
     * Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a>
     */
    or?: Array<string>,
    /**
     * Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a>
     */
    sort?: Array<string>,
    /**
     * Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a>
     */
    join?: Array<string>,
    /**
     * Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a>
     */
    limit?: number,
    /**
     * Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a>
     */
    offset?: number,
    /**
     * Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a>
     */
    page?: number,
    /**
     * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
     */
    cache?: number,
  }): CancelablePromise<(GetManyGameCategoryEntityResponseDto | Array<GameCategoryEntity>)> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/games/categories',
      query: {
        'fields': fields,
        's': s,
        'filter': filter,
        'or': or,
        'sort': sort,
        'join': join,
        'limit': limit,
        'offset': offset,
        'page': page,
        'cache': cache,
      },
    });
  }
}
