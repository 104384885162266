/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GameOfWeekNotFoundResponse = {
  message: Record<string, any>;
  code: GameOfWeekNotFoundResponse.code;
  data: number;
};
export namespace GameOfWeekNotFoundResponse {
  export enum code {
    ERROR_GAME_OF_WEEK_NOT_FOUND = 'error.game-of-week.not-found',
  }
}

