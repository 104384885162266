import { ApiError } from './generated-client';

// eslint-disable-next-line arrow-body-style
export const getErrorMessage = (e: unknown, defaultMessage = 'Something went wrong'): string => {
  const { message } = (e as ApiError).body ?? (e as Error);

  if (typeof message !== 'string' && message !== undefined) {
    return String(message);
  }

  return message || defaultMessage;
};
