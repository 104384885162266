/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GameOfWeekImageSaveErrorResponse = {
  message: string;
  code: GameOfWeekImageSaveErrorResponse.code;
  /**
   * Name of the image
   */
  data: string;
};
export namespace GameOfWeekImageSaveErrorResponse {
  export enum code {
    ERROR_IMAGE_SAVE_FAILED = 'error.image.save-failed',
  }
}

