/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LangResponse } from '../models/LangResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LanguagesApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns LangResponse
   * @throws ApiError
   */
  public getLanguages(): CancelablePromise<Array<LangResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/languages',
    });
  }
}
