import type { RouteLocationRaw, RouteRecordRaw } from 'vue-router';

export enum MENU_ELEMENT_TYPE {
  ITEM = 'item',
  SUBMENU = 'submenu'
}

export type MenuTreeItem = ({
  type: MENU_ELEMENT_TYPE.ITEM;
  to: RouteLocationRaw;
} | {
  type: MENU_ELEMENT_TYPE.SUBMENU;
  children: MenuTreeItem[],
}) & {
  key: string;
  title: string;
  icon?: string;
}

type RouteTransform = (route: RouteRecordRaw) => RouteRecordRaw;

const filterMenuRoutes = (routes: RouteRecordRaw[]) => routes.filter((r) => {
  // TODO: сделать мощный правильный фикс
  // Проблема в определении роутов, очищенных после фильтрации по правам юзера. Пока хотфикс
  const childrenIsEmpty = r.children !== undefined && r.children.length === 0;
  return r.meta?.showInMenu === true && !childrenIsEmpty;
});

const buildTree = (plainRoute: RouteRecordRaw, transforms: RouteTransform[]): MenuTreeItem => {
  const route = transforms.reduce((r, transform) => transform(r), plainRoute);
  const children = filterMenuRoutes(route.children ?? []);

  const hasManyChildren = children.length > 1;
  const hasPinAsSubMenuMeta = route.meta?.pinAsSubMenu === true;

  const isSubmenu = hasPinAsSubMenuMeta || hasManyChildren;

  if (isSubmenu === true) {
    const buildChildren = (r: RouteRecordRaw) => buildTree(r, transforms);
    return {
      key: `${(route.name?.toString() ?? route.meta?.title ?? 'menu')}__${route.path}`,
      type: MENU_ELEMENT_TYPE.SUBMENU,
      title: String(route.meta?.title ?? route.name),
      icon: route.meta?.icon,
      // name для сабменю не обязателен. надеюсь никогда не стрельнет
      children: children.map(buildChildren),
    };
  }

  const routeToPick = children[0] ?? route;

  return {
    key: `${(routeToPick.name?.toString() ?? routeToPick.meta?.title ?? 'item')}__${routeToPick.path}`,
    type: MENU_ELEMENT_TYPE.ITEM,
    title: String(routeToPick.meta?.title ?? routeToPick.name),
    to: { name: routeToPick.name, params: routeToPick.meta?.params },
    icon: routeToPick.meta?.icon,
  };
};

/*
 * Билдит дерево меню из декларации роутов vue-router
 * Для работы требует некоторые поля в meta. Сорри, я не добавил валидацию на них.
 * Но добавил их типы в проект. Ищи свищи
*/
export const buildMenuTreeFromRoutes = (
  routes: RouteRecordRaw[],
  transforms: RouteTransform[] = [],
): MenuTreeItem[] => {
  const resolved = buildTree({
    path: 'root', // не бойтесь, это для консистентности рекурсии
    redirect: 'root', // на самом деле не используется, но типизация требует
    name: 'root',
    meta: { showInMenu: true, title: 'root' },
    children: routes,
  }, transforms);

  return 'children' in resolved ? resolved.children : [resolved];
};
