import type { MaybeRefOrGetter } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { createQueryObject } from '@/lib/request-builder';
import { client, type CategoryPresetsEntity } from '@/api';

const BASE_FIELDS = ['id', 'nameEn', 'url'] as const;
type BaseFields = typeof BASE_FIELDS[number];

export type GameCategoryPresetBase = Pick<CategoryPresetsEntity, BaseFields>;

const fetcherBase = () => client.categoryPresets.getCategoryPresets(createQueryObject({
  fields: [...BASE_FIELDS] as (keyof CategoryPresetsEntity)[],
})) as Promise<GameCategoryPresetBase[]>;

type BaseOptions = {
  enabled?: MaybeRefOrGetter<boolean | undefined>,
  retry?: boolean | number,
};

export const useGameCategoryPresetsBase = (opts: BaseOptions = {}) => useQuery({
  queryKey: ['category-presets/base'],
  queryFn: fetcherBase,
  ...opts,
});
