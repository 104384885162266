/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Game status - enabled/disabled
 */
export enum GAME_STATUS {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
}
