/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Provider Key
 */
export enum PROVIDER_KEY {
  INFINGAMES = 'infingames',
  SOFTSWISS = 'softswiss',
  FUNDIST = 'fundist',
}
