/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangePasswordDto } from '../models/ChangePasswordDto';
import type { CreateUserDto } from '../models/CreateUserDto';
import type { GetManyUserEntityResponseDto } from '../models/GetManyUserEntityResponseDto';
import type { ResetPasswordDto } from '../models/ResetPasswordDto';
import type { UpdateUserDto } from '../models/UpdateUserDto';
import type { UserEntity } from '../models/UserEntity';
import type { UserMetaResponse } from '../models/UserMetaResponse';
import type { UserResponse } from '../models/UserResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UsersApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Create a single user
   * @returns UserResponse
   * @throws ApiError
   */
  public createUser({
    requestBody,
  }: {
    requestBody: CreateUserDto,
  }): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/users',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        409: `Email already registered`,
      },
    });
  }
  /**
   * Retrieve multiple users
   * @returns any Get many base response
   * @throws ApiError
   */
  public getUsers({
    fields,
    s,
    filter,
    or,
    sort,
    join,
    limit,
    offset,
    page,
    cache,
  }: {
    /**
     * Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a>
     */
    fields?: Array<string>,
    /**
     * Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a>
     */
    s?: string,
    /**
     * Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a>
     */
    filter?: Array<string>,
    /**
     * Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a>
     */
    or?: Array<string>,
    /**
     * Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a>
     */
    sort?: Array<string>,
    /**
     * Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a>
     */
    join?: Array<string>,
    /**
     * Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a>
     */
    limit?: number,
    /**
     * Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a>
     */
    offset?: number,
    /**
     * Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a>
     */
    page?: number,
    /**
     * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
     */
    cache?: number,
  }): CancelablePromise<(GetManyUserEntityResponseDto | Array<UserEntity>)> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users',
      query: {
        'fields': fields,
        's': s,
        'filter': filter,
        'or': or,
        'sort': sort,
        'join': join,
        'limit': limit,
        'offset': offset,
        'page': page,
        'cache': cache,
      },
    });
  }
  /**
   * Get self info
   * @returns UserResponse
   * @throws ApiError
   */
  public getSelfInfo(): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/self',
    });
  }
  /**
   * Get self metadata (IP, country code resolved by IP)
   * @returns UserMetaResponse
   * @throws ApiError
   */
  public getSelfMeta(): CancelablePromise<UserMetaResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/self-meta',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public changePassword({
    requestBody,
  }: {
    requestBody: ChangePasswordDto,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/users/password/change',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public resetPassword({
    requestBody,
  }: {
    requestBody: ResetPasswordDto,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/users/password/reset',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Retrieve a single user
   * @returns UserEntity Get one base response
   * @throws ApiError
   */
  public getOneUser({
    id,
    fields,
    join,
    cache,
  }: {
    id: number,
    /**
     * Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a>
     */
    fields?: Array<string>,
    /**
     * Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a>
     */
    join?: Array<string>,
    /**
     * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
     */
    cache?: number,
  }): CancelablePromise<UserEntity> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/{id}',
      path: {
        'id': id,
      },
      query: {
        'fields': fields,
        'join': join,
        'cache': cache,
      },
    });
  }
  /**
   * Update a single user
   * @returns UserEntity Response
   * @throws ApiError
   */
  public updateUser({
    id,
    requestBody,
  }: {
    id: number,
    requestBody: UpdateUserDto,
  }): CancelablePromise<UserEntity> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/users/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Delete a single user
   * @returns any Delete one base response
   * @throws ApiError
   */
  public deleteUser({
    id,
  }: {
    id: number,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/users/{id}',
      path: {
        'id': id,
      },
    });
  }
}
