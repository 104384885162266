import { defineStore } from 'pinia';
import { Router, RouteRecordRaw } from 'vue-router';
import { constantRoutes, protectedRoutes } from '@/router/routes';
import { RIGHT } from '@/api';

type Rights = RIGHT[];

export const useRouterStore = defineStore('router', {
  state: () => ({
    routes: [] as RouteRecordRaw[],
  }),

  actions: {
    generateRoutes(rights: Rights, router: Router) {
      const accessedRoutes = Array.isArray(rights) === true
        ? filterProtectedRoutes(protectedRoutes, rights)
        : [];

      this.routes = constantRoutes.concat(accessedRoutes);
      accessedRoutes.forEach((r) => router.addRoute(r));
    },
  },
});

function hasRights(rights: Rights, route: RouteRecordRaw) {
  if (route.meta?.rights) {
    return route.meta.rights[route.meta.rightsResolveStrategy ?? 'every']((right) => rights.includes(right));
  }
  return true;
}

function filterProtectedRoutes(routes: RouteRecordRaw[], rights: Rights) {
  const res: RouteRecordRaw[] = [];

  routes.forEach((route) => {
    const tmp = { ...route };
    if (hasRights(rights, tmp)) {
      if (tmp.children) {
        tmp.children = filterProtectedRoutes(tmp.children, rights);
      }
      res.push(tmp);
    }
  });

  return res;
}
