/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum PROMO_NOMINATION_STATUSES {
  COLLECTING_PERMITS = 'collectingPermits',
  READY_TO_PLAY = 'readyToPlay',
  PLAYING = 'playing',
  FINISHED = 'finished',
  EXPIRED = 'expired',
  LIMIT_EXCEEDED = 'limitExceeded',
  ZERO_WIN = 'zeroWin',
}
